
export default {
  name: "DefaultLayout",
  data() {
    return {
      drawer: false,
      menu: [
        { title: "$vuetify.pages.profile", to: "Profile" },
        { title: "$vuetify.pages.changPassword", to: "Password" },
        { title: "$vuetify.pages.manageSub", to: "Subscriptions" },
        // { title: "$vuetify.pages.settings", to: "Settings" },
      ],
      searchData: null,
      activator: "",
      dialog: false,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    getCurrentTab() {
      let tab = null;
      if (this.$route.query) {
        if (this.$route.query.tab) {
          tab = this.$route.query.tab;
        }
      }
      return tab;
    },
    userName() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.name : "user";
    },
    userAvatar() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.avatar : null;
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    async logOut() {
      await this.$auth.logout().then(() => {
        this.$router.push(this.localePath("/"));
      });
    },
  },
};
