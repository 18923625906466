
import { mapState } from "vuex";
export default {
  name: "DashboardNavigation",
  data() {
    return {
      showUpgradeBtn: true,
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: "lightning",
          title: `$vuetify.pages.marketSum`,
          to: `/market-sum`,
        },
        {
          icon: "list-boxes",
          title: `$vuetify.pages.sectors`,
          to: `/sectors`,
        },
        {
          icon: "shares",
          title: `$vuetify.pages.myShares`,
          to: `/dashboard/my-shares`,
          name: "shares",
        },
        {
          icon: "wallets",
          title: `$vuetify.pages.walletsManagement`,
          to: `/dashboard/wallets-management`,
          name: "wallets",
        },
        {
          icon: "favorites",
          title: `$vuetify.pages.favorites`,
          to: `/dashboard/favorites-list`,
        },
        {
          icon: "banknote",
          title: `$vuetify.pages.distributions`,
          to: `/distributions`,
        },
        {
          icon: "eye",
          title: `$vuetify.pages.discover`,
          to: `/tools/discover`,
        },
        {
          icon: "calendar",
          title: `$vuetify.pages.calendar`,
          to: `/dashboard/calendar`,
        },
        {
          icon: "comparisons",
          title: `$vuetify.pages.comparisons`,
          to: `/dashboard/comparisons`,
          name: "comparisons",
        },

        {
          icon: "news",
          title: `$vuetify.pages.news`,
          to: `/news`,
        },
      ],
      miniVariant: false,
      right: false,
      rightDrawer: true,
      mini: false,
    };
  },
  computed: {
    ...mapState("general", ["userProfile"]),
    // filteredItems() {
    //   if (
    //     this.$auth.user?.id == 8257 ||
    //     this.$auth.user?.id == 80 ||
    //     this.$auth.user?.id == 15053
    //   ) {
    //     // Show all items for user_id= 8257
    //     return this.items;
    //   } else {
    //     // Exclude walletsManagement, shares and comparisons for other users
    //     return this.items.filter(
    //       (item) => item.name !== "shares" && item.name !== "wallets"
    //     );
    //   }
    // },
  },
  mounted() {
    this.$store.dispatch("general/getUserProfile").then(() => {
      if (this.userProfile?.plan_id == 3) {
        this.showUpgradeBtn = false;
      }
    });
  },
  methods: {
    generateLink(subscriptionType) {
      const selectedPlan = this.$auth.user.plan_id === 1 ? "pro" : "plus";
      const queryParams = { subscriptionType, selectedPlan };
      return this.localePath({
        path: "/dashboard/upgrade-subscribtion",
        query: queryParams,
      });
    },
    controlSidebar() {
      this.mini = !this.mini;
      this.$emit("sidebarChange", this.mini);
    },
  },
};
