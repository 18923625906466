
export default {
  computed: {
    userName() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.name : "user";
    },
    userAvatar() {
      return this.$auth.$state.loggedIn ? this.$auth.$state.user.avatar : null;
    },
  },
  data() {
    return {
      items: [
        {
          title: `userName`,
          delay: 0.1,
          items: [
            {
              title: `${this.$vuetify.lang.t("$vuetify.pages.profile")}`,
              to: "/dashboard/account?tab=Profile",
              icon: "mdi-account",
            },
            {
              title: `${this.$vuetify.lang.t("$vuetify.pages.changPassword")}`,
              to: "/dashboard/account?tab=Password",
              icon: "mdi-key",
            },
            {
              title: `${this.$vuetify.lang.t("$vuetify.pages.manageSub")}`,
              to: "/dashboard/account?tab=Subscriptions",
              icon: "mdi-credit-card-outline",
            },
            {
              title: `${this.$vuetify.lang.t(
                "$vuetify.pages.transactionHistory"
              )}`,
              to: "/dashboard/account?tab=Payments",
              icon: "mdi-text-box-outline",
            },
          ],
        },
        {
          icon: "lightning",
          title: `$vuetify.pages.marketSum`,
          to: `/market-sum`,
          delay: 0.2,
        },
        {
          icon: "list-boxes",
          title: `$vuetify.pages.sectors`,
          to: `/sectors`,
          delay: 0.3,
        },
        {
          icon: "shares",
          title: `$vuetify.pages.myShares`,
          to: `/dashboard/my-shares`,
          delay: 0.4,
          name: "shares",
        },
        {
          icon: "wallets",
          title: `$vuetify.pages.walletsManagement`,
          to: `/dashboard/wallets-management`,
          delay: 0.5,
          name: "wallets",
        },
        {
          icon: "favorites",
          title: `$vuetify.pages.favorites`,
          to: `/dashboard/favorites-list`,
          delay: 0.6,
        },
        {
          icon: "banknote",
          title: `$vuetify.pages.distributions`,
          to: `/distributions`,
          delay: 0.7,
        },
        {
          icon: "eye",
          title: `$vuetify.pages.discover`,
          to: `/tools/discover`,
          delay: 0.8,
        },
        {
          icon: "calendar",
          title: `$vuetify.pages.calendar`,
          to: `/dashboard/calendar`,
          delay: 0.9,
        },
        {
          icon: "comparisons",
          title: `$vuetify.pages.comparisons`,
          to: `/dashboard/comparisons`,
          delay: 1,
          name: "comparisons",
        },

        {
          icon: "news",
          title: `$vuetify.pages.news`,
          to: `/news`,
          delay: 1.2,
        },
      ],
    };
  },
};
